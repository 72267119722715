<template>
  <div>
      <big-title>
         Sistemas <span>recuperados</span> en venta.
      </big-title>
      <vx-card class="mt-4" title="Descubre sistemas recuperados con ¡precios especiales!">
          <div class="vx-row">
            <div class="vx-col w-full">
              <p class="mb-5">
                A continuación podrás encontrar los proyectos recuperados de solicitantes que no pudieron continuar
                con su crédito. Estos equipos tienen muy poco tiempo de uso, están en excelente estado y cuentan con
                garantía de fabricante <strong>¿Te gustaría instalarlos en tu hogar o negocio? ¿Conoces a alguien que esté
                interesado?</strong> Comparte en tus redes sociales estos equipos con ¡precios especiales!
              </p>              
              <div class="flex flex-wrap mt-5">
                <share-network
                    v-for="network in networks"
                    :network="network.network"
                    :key="network.network"
                    :url="sharing.url"
                    :title="sharing.title"
                    :description="sharing.description"
                    :quote="sharing.quote"
                >
                    <vs-button type="flat" :color="network.color" class="mr-4" icon-pack="feather" :icon="network.icon"></vs-button>
                </share-network>
              </div>


              <p class="mt-5">
                Filtra por ubicación los proyectos en venta o contáctanos a través de whatsapp en el (81) 1577 9100
                y en el chat de la plataforma para más información.<strong> Recuerda que estos proyectos también
                se encuentran públicos en redgirasol.com/recuperados</strong>
              </p>


            </div>
            <div class="vx-col w-full">
              <div class="vx-col w-full flex">
                  <vs-select
                  placeholder="Estado"
                  class="mt-5"
                  v-model.lazy="stateSelected"
                  >
                    <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in activeStates" />
                  </vs-select>
                  <vs-button color="primary" class="ml-2 mt-5" @click="getProjects(stateSelected)">Filtrar</vs-button>
              </div>
            </div>
          </div>
      </vx-card>
      
      <div v-if="isMounted" class="vx-row">
        <div class="vx-col md:w-1/3 w-full flex justify-center" v-for="(project, index) in projects" :key="index">
            <vx-card class="mt-4">
                <router-link :to="{name: 'recoverdProjectDetail', params: {id:project.id, project: project, states: states}}">
                  <img :src="getCoverUrl(project)" alt="content-img" class="responsive rounded-lg">
                </router-link>
                <div class="my-6">
                  <template v-if="project.total_capacity">
                    <h5 class="mb-2">Sistema #{{project.id}} de <span class="text-primary">{{project.total_capacity}}</span></h5>
                    <p class="text-grey">en {{ project.location  }}</p>
                  </template>
                  <template v-else>
                    <h5 class="mb-2">Sistema #{{project.id}}</h5>
                    <p class="text-grey">en {{ project.location  }}</p>
                  </template>
                </div>
                <vs-divider></vs-divider>
                <div class="flex justify-between flex-wrap">
                    <span>
                        <p class="text-xl">${{format_price(project.saving_per_year/12)}}</p>
                        <p class="text-grey">Ahorro mensual</p>
                    </span>
                    <span>
                        <p class="text-xl">{{ decimalPercentFormat(project.discount,0)  }}</p>
                        <p class="text-grey">Descuento</p>
                    </span>
                </div>
            </vx-card>

        </div>
      </div>

  </div>
</template>

<script>
import formatHelper from '@components/mixins/formatHelper';
export default {
  name: "RecoveredProjects",
  mixins: [formatHelper],
  data (){
    return {
      isMounted:false,
      firstTime:true,
      states:[],
      projects:[],
      stateSelected:0,
      activeStates:[],
      networks: [
          { network: 'facebook', name: 'Facebook', icon: 'icon-facebook', color: 'dark' },
          { network: 'twitter', name: 'Twitter', icon: 'icon-twitter', color: 'dark' },
          { network: 'whatsapp', name: 'Whatsapp', icon: 'icon-phone', color: 'dark' },
      ],
      sharing: {
          url: 'https://www.redgirasol.com/recuperados',
          title: 'RedGirasol tiene paneles solares a precios especiales.',
          description: 'Entra y comienza a ahorrar en tu recibo de luz.',
          quote: 'RedGirasol tiene paneles solares a precios especiales, entra y comienza a ahorrar en tu recibo de luz.',
      },
    }
  },
  async mounted () {
    this.isMounted = false;
    await this.getStates();
    await this.getProjects();
    this.isMounted = true;
  },
  methods: {
    async getStates() {
      this.showLoading(true);
      this.states = [];
      const res = await this.publicApiGet('/api/register/states');
      this.states = res.data;
      this.showLoading(false);
    },
    async getProjects(stateId = 0) {
      this.showLoading(true);
      if (this.firstTime) {
        var allStates = {
          id:0,
          name:"Estados"
        };
        this.activeStates.push(allStates);          
        this.firstTime = false;
      }
      this.projects = [];
      const res = await this.publicApiGet('/api/projects/recovered/' + stateId);
      this.projects = res.data;
      this.projects.forEach(p => {
        var projectState = this.states.find(s => s.id === p.state_id);
        if (projectState) {
          if (!this.activeStates.some(astate => astate.id === projectState.id)){
            this.activeStates.push(projectState);          
          }
        }
      });
      this.showLoading(false);
    },
    getCoverUrl(project){
      var url = `--`;
      if (project.id) {
        url = `https://cdn.redgirasol.com/covers_proyectos/resale/project_cover_resale_${project.id}.jfif`;
        if (this.checkIfImageExists(url) === "false") {
          url = `https://cdn.redgirasol.com/covers_proyectos/resale/project_cover_resale_${project.id}.jpeg`;
          if (!this.checkIfImageExists(url) === "false") {
            url = `https://cdn.redgirasol.com/covers_proyectos/resale/project_cover_resale_${project.id}.jpg`;
          }
        }
      }
      return url;
    },
    checkIfImageExists(url) {
      const img = new Image();
      img.src = url;
      var response = false;
      if (img.complete) {
        response = true;
      }else{
        img.onload = () => {
          response = true;
        };        
        img.onerror = () => {
          response = false;
        };
      }
      return response;
    },
  }
}
</script>

<style scoped>

</style>